import React, { useLayoutEffect, useRef, useEffect } from "react";
import avatar from '../avatar-personnel.svg'
import { OrgChart } from "d3-org-chart";

const Tree = (props, ref) => {
    const d3Container = useRef(null);
    let chart = null;
    
    useLayoutEffect(() => {
        if (props.data && d3Container.current) {
            if (!chart) {
                chart = new OrgChart();
            }

            chart
                .container(d3Container.current)
                .data(props.data)
                .svgWidth(500)
                .initialZoom(0.8)
                .onNodeClick((d) => {
                    props.onNodeClick(d);
                })
                .expandAll()
                .nodeContent(function (d, i, arr, state) {
                    const color = '#FFFFFF';
                    const imageDiffVert = 25 + 2;
                    return `
                            <div style='width:${
                              d.width
                            }px;height:${d.height}px;padding-top:${imageDiffVert - 2}px;padding-left:1px;padding-right:1px'>
                                    <div style="font-family: 'Inter', sans-serif;background-color:${color};  margin-left:-1px;width:${d.width - 2}px;height:${d.height - imageDiffVert}px;border-radius:10px;border: 1px solid #E4E2E9">
                                        <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px"></div>
                                        <div style="background-color:${color};margin-top:${-imageDiffVert - 20}px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;" ></div>
                                        <div style="margin-top:${
                                          -imageDiffVert - 20
                                        }px;">   <img src=" ${avatar}" style="margin-left:${20}px;border-radius:100px;width:40px;height:60px;" /></div>
                                        <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">  ${
                                          d.data.name
                                        } </div>
                                        <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${
                                          d.data.department
                                        } </div>
            
                                    </div>
                                </div>
                                        `;
                  })
                .render();
        }
    }, [props.data, d3Container.current]);

    return (
        <div>
            <div ref={d3Container} />
        </div>
    );
}

export default Tree