import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React from 'react'
import OrgChartComponent from './components/chart'

function App() {
  return (
    <Router>
      <Routes>
        <Route path = "/" element={<OrgChartComponent />} />
        <Route path = "/orgchart" element={<OrgChartComponent />} />
      </Routes>
    </Router>
  );
}

export default App;