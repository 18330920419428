import React from "react";
import { MdClose } from "react-icons/md";
import "../expandedView.css"

const EmployeeDetailsCard = (props) => {
  return (
    <div className="card">
      <button className="close" onClick={props.handleClose}>
        <MdClose />
      </button>
      <h2>{props.employee.data.name}</h2>
      <p>Department: {props.employee.data.department}</p>
      <p>Office: {props.employee.data.office}</p>
      <p>Skills: {props.employee.data.skills.join(", ")}</p>
    </div>
  );
};

export default EmployeeDetailsCard;
