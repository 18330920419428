import React, { useState, useEffect } from "react";
import Tree from "./tree";
import EmployeeDetailsCard from "./expandedView";

const OrgChartComponent = (props) => {
    const [data, setData] = useState(null)
    const [currEmployee, setCurrEmployee] = useState(null)

    function onNodeClick(node) {
        setCurrEmployee(node)
    }

    const handleClose = () => setCurrEmployee(null)

    function createHierarchy(json) {
        var orgJson = json.organization;
        var currID = 0;
        var hierarchy = [
            {
                id: 0,
                parentId: undefined,
                name: "CEO",
                department: "Company",
                office: "Office",
                skills: ["Everything"]
            },
        ];
        
        // iterate th rough json
        orgJson.departments.forEach((department) => {
            currID++;
            var currParent = currID;

            department.employees.forEach((employee) => {
                currID++;
                var editedEmployee = {
                    name: employee.name,
                    department: employee.department,
                    salary: employee.salary,
                    office: employee.office,
                    skills: employee.skills,
                };

                // if manager assign ID to currParent
                if (employee.isManager) {
                    editedEmployee.id = currParent
                    editedEmployee.parentId = 0
                } else {
                    editedEmployee.id = currID

                    // if undefined department, put directly under top level
                    if (editedEmployee.department === undefined) {
                        editedEmployee.parentId = 0
                    } else {
                        editedEmployee.parentId = currParent
                    }
                }

                hierarchy.push(editedEmployee)
            });
        });

        setData(hierarchy)
    }

    useEffect(() => {
        fetch("https://org-chart.pages.dev/chart")
            .then((response) => response.json())
            .then((json) => createHierarchy(json))
    }, [true]);

    return (
        <div>
            <p>
                Click and drag to pan, scroll to zoom. Click on an employee for employee details.
                <br/>
                Click on the arrows to expand and collapse nodes. 
            </p>
            <Tree
                onNodeClick={onNodeClick}
                data={data}
            />
            {/* render an employee if state is set */}
            {currEmployee && (
                <EmployeeDetailsCard
                    employees={props.data}
                    employee={currEmployee}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
};

export default OrgChartComponent;
